import React from "react"

import Layout from "../components/layout"

import { motion } from 'framer-motion'
import AnimationFadeIn from "../animations/animation-fade-in";
import AnimationFromRight from "../animations/animation-from-right";
import LogoSmall from '../components/images/logo-small'
import LogoMobileSmall from '../components/images/logo-mobile-small'
import Seo from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import ImageUser from '../components/images/image-user'

const User = props => (
    <div className="user-item">
        <ImageUser src={props.image} />
        <div className="user-name">
            {props.username}
        </div>
        <div className="user-position">
            {props.position}
        </div>
        <a href={"mailto:" + props.email}>
            <FontAwesomeIcon icon={faEnvelope}/>
        </a>
    </div>
)

const Team = () => (
    <Layout>
        <Seo title="team" description="Unser kleines Agentur-Team ist jung und gut gemischt. Für deinen Shop haben wir neben Know-How auch ein Netzwerk an starken Partnern im E-Commerce."/>
        <LogoSmall title="ps:dreizehn GmbH"  />
        <LogoMobileSmall title="ps:dreizehn GmbH"  />
        <motion.div variants={AnimationFadeIn} transition="spring" initial="hidden" animate="visible" exit="exit" className="main--left main--black">
            <h1 className="subpage--headline">team</h1>
            <div className="content-wrapper">
                <div className="content">
                    <User
                        image='user/neu/alex.jpg'
                        username="Alexander Schuck"
                        position="CEO"
                        email="alexander.schuck@psdreizehn.de"
                    />
                    <User
                        image='user/neu/lars.jpg'
                        username="Lars Hamann"
                        position="CTO"
                        email="lars.hamann@psdreizehn.de"
                    />
                    <User
                        image='user/user.png'
                        username="Alina Babeck"
                        position="Executive Assistant"
                        email="alina.babeck@psdreizehn.de"
                    />
                </div>
            </div>
        </motion.div>
        <motion.div variants={AnimationFromRight} transition="spring" initial="hidden" animate="visible" exit="exit" className="main--right main--white">
            <div className="content-wrapper">
                <div className="content">
                    <User
                        image='user/user.png'
                        username="Lydia Weniger"
                        position="Full-Stack Developer, Senior E-Commerce Engineer"
                        email="lydia.weniger@psdreizehn.de"
                    />
                    <User
                        image='user/user.png'
                        username="Andreas Bader"
                        position="E-Commerce Engineer"
                        email="andreas.bader@psdreizehn.de"
                    />
                    <User
                        image='user/neu/karsten.jpg'
                        username="Karsten Montag"
                        position="Graphic Designer"
                        email="karsten.montag@psdreizehn.de"
                    />
                </div>
            </div>
        </motion.div>
    </Layout>
)

export default Team